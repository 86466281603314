import React, { useMemo } from 'react';
import { IcArrowDown } from '@peoplefund/components/common/Icon';
import * as Styled from './index.styled';
import { PCProps, SiteMenu } from '../../index.model';
import { GnbStyleProps } from '@peoplefund/components/common/Gnb/index.styled';
type MenuItemProps = SiteMenu & {
  readonly bgColorKey: GnbStyleProps['bgColorKey'];
  readonly type: 'text' | 'button';
};
const Menu: React.FC<MenuItemProps> = ({
  bgColorKey,
  pcTitle,
  list,
  type
}) => {
  const StyledTitle = type === 'text' ? Styled.MenuTitle : Styled.MenuButton;
  return <Styled.MenuWrapper onClick={list.length === 1 ? list[0].onClick : undefined} data-sentry-element="unknown" data-sentry-component="Menu" data-sentry-source-file="index.tsx">
			<StyledTitle bgColorKey={bgColorKey} positionTopType="PC" data-sentry-element="StyledTitle" data-sentry-source-file="index.tsx">
				{pcTitle}
				{list.length > 1 && <IcArrowDown pathFill={`${bgColorKey === 'basic' ? '#b0bec5' : 'white'}`} />}
			</StyledTitle>
			{list.length > 1 && <Styled.DropDown className="menu-drop-down">
					{list.map((item, idx) => <Styled.DropDownItem key={idx} onClick={item.onClick}>
							{item.title}
						</Styled.DropDownItem>)}
				</Styled.DropDown>}
		</Styled.MenuWrapper>;
};
const PcGnb: React.FC<PCProps> = ({
  bgColorKey,
  siteMenu
}) => {
  const [left, right, button] = useMemo(() => {
    const left = siteMenu.filter(item => item.position === 'LEFT');
    const right = siteMenu.filter(item => item.position === 'RIGHT');
    const button = siteMenu.filter(item => item.position === 'BUTTON');
    return [left, right, button];
  }, [siteMenu]);
  return <Styled.Wrapper data-sentry-element="unknown" data-sentry-component="PcGnb" data-sentry-source-file="index.tsx">
			<Styled.MenuSection data-sentry-element="unknown" data-sentry-source-file="index.tsx">
				{left.map((menu, idx) => <Menu key={idx} {...menu} bgColorKey={bgColorKey} type="text" />)}
			</Styled.MenuSection>
			<Styled.MenuSectionDivider data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
			<Styled.MenuSection data-sentry-element="unknown" data-sentry-source-file="index.tsx">
				{right.map((menu, idx) => <Menu key={idx} {...menu} bgColorKey={bgColorKey} type="text" />)}
			</Styled.MenuSection>
			<Styled.MenuSection data-sentry-element="unknown" data-sentry-source-file="index.tsx">
				{button.map((menu, idx) => <Menu key={idx} {...menu} bgColorKey={bgColorKey} type="button" />)}
			</Styled.MenuSection>
		</Styled.Wrapper>;
};
export default PcGnb;