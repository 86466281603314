import { FirebaseModule } from '@peoplefund/db/firebase';
import { child, get, ref } from 'firebase/database';

class FirebaseDBModule<FirebaseDataType> extends FirebaseModule {
	getData = (
		category: string,
		key: string,
		success: (data: FirebaseDataType) => void,
		error: (error?: unknown) => void
	) => {
		if (!this.app || !this.db) {
			return error();
		}

		const dbRef = ref(this.db, category);
		return get(child(dbRef, key))
			.then((snapshot) => {
				if (snapshot.exists()) {
					success(snapshot.val());
				} else {
					error(`${key}에 해당하는 데이터가 존재하지 않습니다.`);
				}
			})
			.catch(error);
	};
}

export default FirebaseDBModule;
