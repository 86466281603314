import { NextRouter } from 'next/router';
import PageUrls from '@peoplefund/constants/page-urls';
import { gtag, gtagCommonEvent, GtagEventCategory } from '@peoplefund/utils/marketing-script/gtag.util';
import LOG_EVENT_NAME from '@peoplefund/constants/log-event';
import { MenuPosition, SiteMenu, TitleTemplate, UserMenu } from '@peoplefund/components/common/Gnb/index.model';

export const getSiteMenu = (
	loggedIn: boolean,
	showLoansMypageMenu: boolean,
	userName: string,
	isMobile: boolean,
	logout: () => void,
	router: NextRouter
): SiteMenu[] => {
	const commonMenu: SiteMenu[] = [
		{
			pcTitle: '투자',
			mobileTitle: '투자',
			position: 'LEFT',
			list: [
				{
					showMenu: true,
					title: '투자',
					onClick: () => {
						gtagCommonEvent(LOG_EVENT_NAME.com_gnb___ck_invest);
						location.href = PageUrls.investing.LIST;
					},
				},
				{
					showMenu: true,
					title: `투자장바구니${TitleTemplate.CartItemCount}`,
					onClick: () => {
						gtagCommonEvent(LOG_EVENT_NAME.com_main_gnb__ck_shop);

						router.push(PageUrls.investing.CART);
					},
				},
			],
		},
		{
			pcTitle: '대출',
			mobileTitle: '대출',
			position: 'LEFT',
			list: [
				{
					showMenu: true,
					title: 'AI대출비교',
					onClick: () => {
						gtagCommonEvent(LOG_EVENT_NAME.com____ck_ai);

						gtag('event', LOG_EVENT_NAME.GNBCompareClick, { event_category: GtagEventCategory.COMMON });
						router.push(PageUrls.loansCompare.INTRO);
					},
				},
				{
					showMenu: true,
					title: '주택담보대출',
					onClick: () => {
						gtagCommonEvent(LOG_EVENT_NAME.com____ck_ml);

						gtag('event', LOG_EVENT_NAME.GNBMortgageLoansClick, { event_category: GtagEventCategory.COMMON });
						router.push(PageUrls.mortgageLoans.INTRO);
					},
				},
			],
		},
		{
			pcTitle: '법인 투자안내',
			mobileTitle: '',
			position: 'RIGHT',
			list: [
				{
					showMenu: true,
					title: '법인 투자안내',
					onClick: () => {
						window.location.href = PageUrls.event.CORPORATE;
					},
				},
			],
		},
		{
			pcTitle: '회사소개',
			mobileTitle: '회사소개',
			position: 'RIGHT',
			list: [
				{
					showMenu: true,
					title: '회사소개',
					onClick: () => {
						gtagCommonEvent(LOG_EVENT_NAME.com_main_gnb__ck_pfcthome);

						window.open(PageUrls.pfct.HOME, '_blank');
					},
				},
			],
		},
		{
			pcTitle: '이용안내',
			mobileTitle: '이용안내',
			position: 'RIGHT',
			list: [
				{
					showMenu: true,
					title: 'FAQ',
					onClick: () => {
						location.href = PageUrls.company.FAQ;
					},
				},
				{
					showMenu: true,
					title: '공지사항',
					onClick: () => {
						router.push(PageUrls.company.NOTICE);
					},
				},
				{
					showMenu: true,
					title: '공시지표',
					onClick: () => {
						location.href = PageUrls.company.STATS;
					},
				},
			],
		},
	];

	const extraMenu: SiteMenu[] = [];
	const commonExtraMenuList = [
		{
			showMenu: true,
			title: '설정',
			onClick: () => {
				router.push(PageUrls.auth.SETTING);
			},
		},
		{
			showMenu: loggedIn,
			title: '로그아웃',
			onClick: logout,
		},
	];

	if (isMobile) {
		extraMenu.push({
			pcTitle: '',
			mobileTitle: '설정',
			position: 'BUTTON',
			list: commonExtraMenuList,
		});
	} else {
		const userMenu = getMobileUserMenu(false, loggedIn, showLoansMypageMenu, router)
			.filter((item) => item.visible)
			.reverse();

		if (loggedIn) {
			extraMenu.push({
				pcTitle: `${userName}님`,
				mobileTitle: '',
				position: 'BUTTON' as MenuPosition,
				list: [
					...userMenu.map(({ title, buttonOnClick }) => {
						return {
							showMenu: true,
							title,
							onClick: buttonOnClick,
						};
					}),
					...commonExtraMenuList,
				],
			});
		} else {
			extraMenu.push(
				...userMenu.map(({ title, buttonOnClick }) => {
					return {
						pcTitle: title,
						mobileTitle: '',
						position: 'BUTTON' as MenuPosition,
						list: [
							{
								showMenu: true,
								title,
								onClick: buttonOnClick,
							},
						],
					};
				})
			);
		}
	}
	return [...commonMenu, ...extraMenu];
};

export const getMobileUserMenu = (
	isMobile: boolean,
	loggedIn: boolean,
	showLoansMypageMenu: boolean,
	router: NextRouter
): UserMenu[] => {
	const MY_PAGE_LOANS_TITLE = '대출현황';

	const menu: UserMenu[] = loggedIn
		? [
				{
					title: `투자장바구니${TitleTemplate.CartItemCount}`,
					buttonOnClick: () => {
						gtagCommonEvent(LOG_EVENT_NAME.com_main_gnb__ck_shop);

						router.push(PageUrls.investing.CART);
					},
					buttonColorType: 'lime',
					buttonPurpose: 'sub',
					visible: !isMobile,
				},
				{
					title: MY_PAGE_LOANS_TITLE,
					buttonOnClick: () => {
						location.href = PageUrls.mypage.LOAN.HOME;
					},

					buttonColorType: 'lime',
					buttonPurpose: 'sub',
					visible: true,
				},
				{
					title: '투자현황',
					buttonOnClick: () => {
						location.href = PageUrls.mypage.INVEST;
					},

					buttonColorType: 'lime',
					buttonPurpose: 'sub',
					visible: true,
				},
		  ]
		: [
				{
					buttonColorType: 'gray',
					buttonPurpose: 'main',
					title: '로그인',
					buttonOnClick: () => {
						gtagCommonEvent(LOG_EVENT_NAME.com____ck_login);

						router.push({
							pathname: PageUrls.auth.LOGIN,
							query: {
								r: router.asPath,
							},
						});
					},
					visible: true,
				},
				{
					buttonColorType: 'gray',
					buttonPurpose: 'sub',
					title: '투자회원가입',
					buttonOnClick: () => {
						gtagCommonEvent(LOG_EVENT_NAME.com____ck_create);

						router.push(PageUrls.auth.SIGNUP_FOR_INVESTING);
					},
					visible: true,
				},
		  ];

	if (showLoansMypageMenu) {
		return menu;
	} else {
		return menu.filter((item) => item.title !== MY_PAGE_LOANS_TITLE);
	}
};
