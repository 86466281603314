import styled from '@emotion/styled';
import { Spacing, zIndex } from '@peoplefund/styles/global.constants';
import { basicColors } from '@pfct/purple';
import { GNB_HEIGHT } from '../../index.styled';
const SidePadding = Spacing.m;
export const MenuButton = styled.button`
	padding: 0 12px;
	margin-right: 8px;
	margin-left: auto;
	height: ${GNB_HEIGHT.mobile}px;

	background: transparent;
	border: none;
	outline: none;

	svg {
		display: block;
	}
`;
export const BackDrop = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.3);
	cursor: pointer;
`;
export const Content = styled.div<{
  open: boolean;
}>`
	display: ${({
  open
}) => open ? 'block' : 'none'};
	z-index: ${zIndex.basicLayer - 1};

	position: absolute;
	top: 0;
	right: 0;

	min-width: 310px;
	height: 100vh;
	padding: 14px 0 74px;

	overflow-y: scroll;
	background: ${basicColors.white};
`;
export const CloseButton = styled.button`
	position: absolute;
	top: 2px;
	right: 7px;
	padding: 12px;

	background: transparent;
	border: none;
	outline: none;

	> svg {
		width: 24px;
		height: 24px;
	}
`;
export const UserInfo = styled.h2`
	position: relative;
	margin: 14px 0 0;
	padding: 0 ${SidePadding}px;
`;
export const UserInfoTitle = styled.div`
	margin: 52px 0 0;
	padding: 0;
	font-size: 18px;
	font-weight: bold;
	letter-spacing: -0.4px;
	line-height: 27px;
	color: ${basicColors.gray900};
`;
export const Menu = styled.ul`
	list-style: none;
	padding: 0;
	margin: 0;
`;
export const MenuItemTitle = styled.h3`
	padding: 0 ${SidePadding}px;
	margin: 0;

	font-size: 12px;
	font-weight: bold;
	letter-spacing: -0.2px;
	color: ${basicColors.gray400};
`;
export const MenuItem = styled.li`
	padding: ${Spacing.xxs}px ${SidePadding}px;

	&:hover {
		background: ${basicColors.gray50};
	}
`;
export const Divider = styled.div`
	width: calc(100% - 40px);
	height: 1px;
	margin: ${Spacing.m}px auto;

	background-color: ${basicColors.gray50};
`;