import { Dispatch, SetStateAction, useState } from 'react';
import { WithNetworkStatus } from '@peoplefund/reducers';

const useStateWithNetworkStatus = <T extends Record<string, any>>(
	initialValue: T
): [WithNetworkStatus<T>, Dispatch<SetStateAction<WithNetworkStatus<T>>>] => {
	const res = useState<WithNetworkStatus<T>>({ ...initialValue, networkStatus: 'idle' });

	return res;
};

export default useStateWithNetworkStatus;
