import { css } from '@emotion/react';
import styled from '@emotion/styled';
import PageUrls from '@peoplefund/constants/page-urls';
import { homepageMainfirebaseConfig } from '@peoplefund/db/firebase';
import FirebaseDBModule from '@peoplefund/utils/marketing-script/FirebaseDBModule';
import { Larger, Spacing, useLayoutIsMobile } from '@pfct/purple';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
export const EVENT_BANNER_HEIGHT_MAP = {
  pc: 74,
  mobile: 85
};
const Styled = {
  Wrapper: styled.section`
		position: absolute;
		top: 0;
		display: flex;
		background: #1b1b1b;
		width: 100%;
		height: ${EVENT_BANNER_HEIGHT_MAP.mobile}px;

		${Larger(css`
			top: 0;
			padding: 0;
			justify-content: center;
			height: ${EVENT_BANNER_HEIGHT_MAP.pc}px;
		`)}
	`,
  ContentWrapper: styled.section`
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		cursor: pointer;

		${Larger(css`
			width: 1080px;
		`)}
	`,
  Image: styled.img`
		max-width: 100%;
		height: 100%;

		${Larger(css`
			max-width: 1080px;
		`)}
	`
};
interface EventBannerProps {
  link: string;
  mobileImageUrl: string;
  pcImageUrl: string;
}
const firebaseDB = new FirebaseDBModule<EventBannerProps>(homepageMainfirebaseConfig, 'home');
function EventBanner() {
  const router = useRouter();
  const isMobileLayout = useLayoutIsMobile();
  const [bannerProps, setBannerProps] = useState<EventBannerProps>({
    link: '',
    mobileImageUrl: '',
    pcImageUrl: ''
  });
  const {
    link,
    mobileImageUrl,
    pcImageUrl
  } = bannerProps;
  const src = isMobileLayout ? mobileImageUrl : pcImageUrl;
  const showEventBanner = router.pathname === PageUrls.HOME;
  useEffect(() => {
    if (!showEventBanner) {
      return;
    }
    firebaseDB.getData('home-event-section', 'object', setBannerProps, error => alert(JSON.stringify(error)));
  }, [showEventBanner]);
  if (!showEventBanner) {
    return <></>;
  }
  return <>
			<Styled.Wrapper data-sentry-element="unknown" data-sentry-source-file="EventBanner.tsx">
				<Styled.ContentWrapper onClick={() => {
        window.open(link, '_blank');
      }} data-sentry-element="unknown" data-sentry-source-file="EventBanner.tsx">
					<Styled.Image src={src} data-sentry-element="unknown" data-sentry-source-file="EventBanner.tsx" />
				</Styled.ContentWrapper>
			</Styled.Wrapper>
			<Spacing spaceNumber={isMobileLayout ? EVENT_BANNER_HEIGHT_MAP.mobile : EVENT_BANNER_HEIGHT_MAP.pc} data-sentry-element="Spacing" data-sentry-source-file="EventBanner.tsx" />
		</>;
}
export default EventBanner;