import { css, SerializedStyles } from '@emotion/react';

export const MediaQuerySize = {
	Mobile: 768,
	PC: 1024,
	NewMobile: 1080, // TODO: 임시의 Mobile 여부 파악기준
};

export const Smaller = (style: SerializedStyles): SerializedStyles => css`
	@media (max-width: ${MediaQuerySize.Mobile}px) {
		${style}
	}
`;

export const Larger = (style: SerializedStyles): SerializedStyles => css`
	@media (min-width: ${MediaQuerySize.Mobile + 1}px) {
		${style}
	}
`;
