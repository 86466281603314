import styled from '@emotion/styled';
import { basicColors } from '@pfct/purple';
import { css } from '@emotion/react';
const NoticationBgColor = basicColors.red400;
const ContentHeight = '16px';
const CommonStyle = css`
	background-color: ${NoticationBgColor};
`;
export const Dot = styled.div`
	${CommonStyle};

	width: 5px;
	height: 5px;
	border-radius: 50%;
`;
export const Badge = styled.div`
	${CommonStyle};

	display: inline-block;
	height: ${ContentHeight};
	margin-left: 3px;
	padding: 0 5px;
	border-radius: 8px;
`;
export const BadgeContent = styled.div`
	line-height: ${ContentHeight};
	font-size: 11px;
	font-weight: 510;
	color: ${basicColors.white};
`;