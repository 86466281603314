import React from 'react';
import { SpacingLayer } from '@peoplefund/components/common/Spacing/index.styled';
export type SizeType = 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl' | 'xxxl' | 'none';
interface Props {
  size: SizeType;
}
export const BasicSpacing: React.FC<Props> = ({
  size = 'm'
}) => {
  return <SpacingLayer size={getSizePixel(size)} data-sentry-element="SpacingLayer" data-sentry-component="BasicSpacing" data-sentry-source-file="index.tsx" />;
};
const getSizePixel = (size: string) => {
  switch (size) {
    case 'xxs':
      return 5;
    case 'xs':
      return 10;
    case 's':
      return 15;
    case 'm':
      return 20;
    case 'l':
      return 25;
    case 'xl':
      return 30;
    case 'xxl':
      return 35;
    case 'xxxl':
      return 40;
    default:
      return 0;
  }
};
export const BasicSpacingS = () => {
  return <BasicSpacing size={'s'} data-sentry-element="BasicSpacing" data-sentry-component="BasicSpacingS" data-sentry-source-file="index.tsx" />;
};
export const BasicSpacingM = () => {
  return <BasicSpacing size={'m'} data-sentry-element="BasicSpacing" data-sentry-component="BasicSpacingM" data-sentry-source-file="index.tsx" />;
};
export default BasicSpacing;