import React from 'react';
import { IconProps } from '../index';
export const IcArrowDown = React.memo<IconProps>(({
  width = 24,
  height = 24,
  className,
  style,
  viewBox = '0 0 24 24',
  pathFill = '#b0bec5'
}) => <svg width={width} height={height} style={style} className={className} viewBox={viewBox}>
			<g fill="none" fillRule="evenodd">
				<path d="M0 0h24v24H0z" />
				<path fill={pathFill} d="M12 13.884L6.424 8.217a.718.718 0 00-1.03 0l-.18.185a.748.748 0 000 1.047l6.237 6.34c.152.154.352.22.55.21a.71.71 0 00.548-.21l6.238-6.339a.75.75 0 000-1.047l-.182-.185a.718.718 0 00-1.03 0L12 13.884z" />
			</g>
		</svg>);