import React, { useCallback, useEffect, useState } from 'react';
import { IcClose } from '@peoplefund/components/common/Icon';
import BasicSpacing, { BasicSpacingS } from '@peoplefund/components/common/Spacing';
import * as Styled from './index.styled';
import { basicColors, SingleButton } from '@pfct/purple';
import Menu from './IcMenu';
import { MobileProps } from '../../index.model';
import LOG_EVENT_NAME from '@peoplefund/constants/log-event';
import { gtagCommonEvent } from '@peoplefund/utils/marketing-script/gtag.util';
const MobileGnb: React.FC<MobileProps> = ({
  title,
  siteMenu,
  userMenu,
  bgColorKey
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const toggleMenu = useCallback(() => {
    setMenuIsOpen(menuIsOpen => !menuIsOpen);
    gtagCommonEvent(LOG_EVENT_NAME.com_main___ck_gnb);
  }, []);
  useEffect(() => {
    if (menuIsOpen) {
      gtagCommonEvent(LOG_EVENT_NAME.com_main_gnb__vw_);
    }
  }, [menuIsOpen]);
  return <>
			{menuIsOpen && <>
					<Styled.BackDrop className="back-drop" onClick={toggleMenu} />
					<Styled.Content open={menuIsOpen}>
						<Styled.CloseButton onClick={toggleMenu}>
							<IcClose pathFill={basicColors.gray200} />
						</Styled.CloseButton>
						<Styled.UserInfo>
							<Styled.UserInfoTitle>{title}</Styled.UserInfoTitle>
							<BasicSpacingS />
							{userMenu.map((item, index, arr) => <React.Fragment key={item.title}>
									<SingleButton colorType={item.buttonColorType} purpose={item.buttonPurpose} type="button" onClick={() => {
              toggleMenu();
              item.buttonOnClick();
            }}>
										{item.title}
									</SingleButton>
									{index !== arr.length && <BasicSpacing size="xs" />}
								</React.Fragment>)}
						</Styled.UserInfo>
						<BasicSpacing size="xxl" />
						{siteMenu.map(({
          mobileTitle,
          list = []
        }, idx) => <React.Fragment key={mobileTitle}>
								{mobileTitle && <Styled.MenuItemTitle>{mobileTitle}</Styled.MenuItemTitle>}
								<Styled.Menu>
									{list.map((menuItem, idx) => menuItem.showMenu && <Styled.MenuItem key={idx} onClick={() => {
              toggleMenu();
              menuItem.onClick?.();
            }}>
													{menuItem.title}
												</Styled.MenuItem>)}
								</Styled.Menu>
								{idx < Object.keys(siteMenu).length && <Styled.Divider />}
							</React.Fragment>)}
					</Styled.Content>
				</>}
			<Styled.MenuButton data-testid="test#MobileMenuButton" onClick={toggleMenu} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
				<Menu fillColor={bgColorKey === 'basic' ? '#000000' : '#ffffff'} data-sentry-element="Menu" data-sentry-source-file="index.tsx" />
			</Styled.MenuButton>
		</>;
};
export default MobileGnb;