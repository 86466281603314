import { initializeApp, FirebaseOptions, FirebaseApp } from 'firebase/app';
import { Database, getDatabase } from 'firebase/database';

const baseFirebaseConfig: FirebaseOptions = {
	apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
	authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
	projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
	storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
	measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};
// TODO: env 로 설정 옮기기
export const homepageMainfirebaseConfig: FirebaseOptions = {
	...baseFirebaseConfig,
	databaseURL: process.env.NEXT_PUBLIC_FIREBASE_HOMEPAGE_DATABASE_URL,
};

export class FirebaseModule {
	app: FirebaseApp | null = null;
	db: Database | null = null;

	constructor(config?: FirebaseOptions, name = 'default') {
		try {
			this.app = initializeApp(config ? config : baseFirebaseConfig, name);
			this.db = getDatabase(this.app);
		} catch (e) {
			console.log('error', e);
			/**
			 * [2022-07-04T07:47:43.413Z]  @firebase/database: FIREBASE FATAL ERROR: Can't determine Firebase Database URL. Be sure to include  a Project ID when calling firebase.initializeApp().
			 */
		}
	}
}
