import React from 'react';
import { IconProps } from '../index';
export const IcClose = React.memo<IconProps>(({
  width = 24,
  height = 24,
  className,
  style,
  viewBox = '0 0 24 24',
  pathFill = 'currentColor'
}) => <svg width={width} height={height} style={style} className={className} viewBox={viewBox}>
			<title>IcClose</title>
			<g fill="none" fillRule="evenodd">
				<path d="M0 0h24v24H0z" />
				<path fill={pathFill} d="M6.81 5.328l5.19 5.19 5.19-5.19a1.048 1.048 0 111.482 1.483L13.482 12l5.19 5.19a1.048 1.048 0 11-1.483 1.482L12 13.482l-5.19 5.19a1.048 1.048 0 11-1.482-1.483L10.518 12l-5.19-5.19a1.048 1.048 0 111.483-1.482z" />
			</g>
		</svg>);