import styled from '@emotion/styled';
import { Nav } from '@peoplefund/components/common/Gnb/index.styled';
import { zIndex } from '@pfct/purple';

export const CpleNav = styled(Nav)<{ backgroundColor: string }>`
	position: fixed;
	padding: 0 15px;
	justify-content: space-between;
	background-color: ${({ backgroundColor }) => backgroundColor};

	z-index: ${zIndex.modal - 1};
`;

export const IconWrapper = styled.div`
	display: flex;
	align-items: center;
`;

export const EmptyIconWrapper = styled.div`
	width: 25px;
`;
