import React, { PropsWithChildren, useMemo, useState } from 'react';
import Gnb, { GnbProps } from '@peoplefund/components/common/Gnb';
import { Navigation, NavigationProps, Spacing, useLayoutIsMobile } from '@pfct/purple';
import CpleInvestFlowNavigationBar from '@peoplefund/components/investing/cple/NavigationBar/flow';
import { GNB_HEIGHT } from '@peoplefund/components/common/Gnb/index.styled';
import EventBanner from '@peoplefund/components/index/@components/EventBanner';
import styled from '@emotion/styled';
import { WebviewHandler } from '@peoplefund/utils/webview-handler/index.util';
type HeaderForNone = {
  type: 'none';
};
type HeaderForGNB = {
  type: 'gnb';
} & Pick<GnbProps, 'backgroundColorKey'>;
type HeaderForNav = {
  type: 'nav';
} & NavigationProps;
type HeaderForCpleInvestFlow = {
  type: 'nav-cple-invest-flow';
} & Pick<GnbProps, 'backgroundColorKey'>;
type PcProps = HeaderForNone | HeaderForGNB;
type MobileProps = HeaderForNone | HeaderForGNB | HeaderForNav | HeaderForCpleInvestFlow;
type SetGlobalLayoutHeaderProps<P, M> = {
  pc: P extends 'gnb' ? HeaderForGNB : HeaderForNone;
  mobile: M extends 'gnb' ? HeaderForGNB : M extends 'nav' ? HeaderForNav : M extends 'nav-cple-invest-flow' ? HeaderForCpleInvestFlow : HeaderForNone;
};
interface GlobalLayoutHeaderContext {
  hideGnb: () => void;
  showGnb: () => void;
  setGlobalLayoutHeader: <P, M>(props: SetGlobalLayoutHeaderProps<P, M>) => void;
  setHasFooter: (hasFooter: boolean) => void;
  hasGlobalLayoutHeaderInMobile: boolean;
  hasGlobalLayoutHeader: boolean;
  hasFooter: boolean;
}
export const GlobalLayoutHeaderContext = React.createContext<GlobalLayoutHeaderContext>({
  hideGnb: () => {
    console.info('');
  },
  showGnb: () => {
    console.info('');
  },
  setGlobalLayoutHeader: () => {
    console.info('');
  },
  setHasFooter: () => {
    console.info('');
  },
  hasGlobalLayoutHeaderInMobile: false,
  hasGlobalLayoutHeader: false,
  hasFooter: false
});
const GlobalLayoutHeaderProvider: React.FC<PropsWithChildren> = ({
  children
}) => {
  const isMobile = useLayoutIsMobile();
  const webviewHandler = WebviewHandler();
  const isAppview = webviewHandler.isWebview;
  const isAppviewExceptMillion = isAppview && webviewHandler.appType !== 'Million';
  const [navVisible, setNavVisible] = useState(false);
  const [gnbVisible, setGnbVisible] = useState(false);
  const [hasFooter, setHasFooter] = useState(false);
  const [mobileProps, setMobileProps] = useState<MobileProps>({
    type: 'none'
  });
  const [pcProps, setPcProps] = useState<PcProps>({
    type: 'none'
  });
  const contextValue: GlobalLayoutHeaderContext = useMemo(() => ({
    hideGnb: () => {
      setGnbVisible(false);
    },
    showGnb: () => {
      setGnbVisible(true);
    },
    hasGlobalLayoutHeaderInMobile: isMobile && (navVisible || gnbVisible),
    hasGlobalLayoutHeader: navVisible || gnbVisible,
    setGlobalLayoutHeader: ({
      pc,
      mobile
    }) => {
      if (isMobile) {
        setMobileProps(mobile);
      } else {
        setPcProps(pc);
      }
    },
    setHasFooter,
    hasFooter: !isAppview && hasFooter
  }), [isMobile, navVisible, gnbVisible, isAppview, hasFooter]);
  const hideHeader = () => {
    setGnbVisible(false);
    setNavVisible(false);
    return null;
  };
  const mobileComp = useMemo(() => {
    if (isAppviewExceptMillion) {
      return hideHeader();
    }
    switch (mobileProps.type) {
      case 'gnb':
        const mobileGnbProps = mobileProps as HeaderForGNB;
        setMobileProps(mobileGnbProps);
        setGnbVisible(true);
        return <Gnb isMobile={true} {...mobileGnbProps} />;
      case 'nav':
        const mobileNavprops = mobileProps as HeaderForNav;
        setNavVisible(true);
        return <Navigation {...mobileNavprops} />;
      case 'nav-cple-invest-flow':
        return <CpleInvestFlowNavigationBar bgColorKey={mobileProps.backgroundColorKey} />;
      default:
        return hideHeader();
    }
  }, [isAppviewExceptMillion, mobileProps]);
  const pcComp = useMemo(() => {
    switch (pcProps.type) {
      case 'gnb':
        const pcGnbProps = pcProps as HeaderForGNB;
        setGnbVisible(true);
        return <Gnb isMobile={false} {...pcGnbProps} />;
      default:
        return hideHeader();
    }
  }, [pcProps]);
  const isNavCpleInvestFlow = mobileProps.type === 'nav-cple-invest-flow';
  return <GlobalLayoutHeaderContext.Provider value={contextValue} data-sentry-element="unknown" data-sentry-component="GlobalLayoutHeaderProvider" data-sentry-source-file="index.tsx">
			<EventBanner data-sentry-element="EventBanner" data-sentry-source-file="index.tsx" />
			{isMobile ? mobileComp : pcComp}
			{isNavCpleInvestFlow && <SpacingWrapper isOn={isNavCpleInvestFlow}>
					<Spacing spaceNumber={GNB_HEIGHT.mobile} />
				</SpacingWrapper>}
			{children}
		</GlobalLayoutHeaderContext.Provider>;
};
export default GlobalLayoutHeaderProvider;
const SpacingWrapper = styled.div<{
  isOn: boolean;
}>`
	transform: translateY(${({
  isOn
}) => isOn ? '0' : '-55px'});
	transition: transform 0.3s ease-in-out;
`;