import React from 'react';
import { IconProps } from '../index';
export const IcTooltip = React.memo<IconProps>(({
  width = 24,
  height = 24,
  className,
  style,
  viewBox = '0 0 24 24'
}) => <svg width={width} height={height} style={style} className={className} viewBox={viewBox}>
			<g fill="none" fillRule="evenodd">
				<path d="M0 0h24v24H0z" />
				<circle cx={12} cy={12} r={7.5} stroke="#B0BEC5" />
				<text fill="#B0BEC5" fontSize={11} fontWeight="bold">
					<tspan x={9.47} y={16}>
						{'?'}
					</tspan>
				</text>
			</g>
		</svg>);