import { useCallback, useEffect, useState } from 'react';
import { InAPICommonError } from '@peoplefund/constants/error/type';
import { useAppDispatch, useAppSelector } from '@peoplefund/store';
import { CartList, fetchCartList } from '@peoplefund/slices/ml-investing';
import useStateWithNetworkStatus from './useStateWithNetworkStatus';
type MortgageCartProps = {
  readonly count: number;
  readonly fetch: () => void;
  readonly error?: InAPICommonError;
  readonly resetError: () => void;
};
type MortgageCartSetting = {
  readonly initialFetch?: boolean;
  readonly token?: string;
};
const useMortgageCartList = ({
  initialFetch = true,
  token
}: MortgageCartSetting): MortgageCartProps => {
  const dispatch = useAppDispatch();
  const cartListCount = useAppSelector(state => state.mlInvesting.cartListCount);
  const [, setCartItemList] = useStateWithNetworkStatus<{
    value: CartList;
  }>({
    value: []
  });
  const [error, setError] = useState<InAPICommonError>();
  const resetError = useCallback(() => setError(undefined), []);
  const fetch = useCallback(async () => {
    if (!token || cartListCount !== 0) {
      return;
    }
    dispatch(fetchCartList({
      action(props) {
        setCartItemList(props);
      }
    }));
  }, [cartListCount, dispatch, setCartItemList, token]);
  useEffect(() => {
    if (initialFetch) {
      fetch();
    }
  }, [initialFetch, fetch]);
  return {
    count: cartListCount,
    fetch,
    error,
    resetError
  };
};
export default useMortgageCartList;