import React from 'react';
export default React.memo<{
  fillColor?: string;
}>(({
  fillColor = '#ffffff'
}) => <svg xmlns="http://www.w3.org/2000/svg" width={18} height={14} viewBox={'0 0 18 14'}>
		<title>IcMenu</title>
		<g fill="none" fillRule="evenodd">
			<g stroke={fillColor} strokeWidth="2">
				<g>
					<path d="M0 1L18 1M0 7L18 7M0 13L12 13" transform="translate(-326 -19) translate(326 19)" />
				</g>
			</g>
		</g>
	</svg>);