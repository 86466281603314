import styled from '@emotion/styled';
import { Spacing } from '@peoplefund/styles/global.constants';
import { basicColors, typography } from '@pfct/purple';
import { GNB_HEIGHT, GnbStyleProps } from '../../index.styled';
export const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`;
export const MenuSection = styled.div`
	padding: 0;
	margin: 0;
	display: flex;
	align-items: center;
`;
export const MenuSectionDivider = styled.div`
	flex: 1;
`;
export const MenuTitle = styled.div<GnbStyleProps>`
	padding: 0 20px;
	line-height: 30px;

	font-size: 16px;
	letter-spacing: -0.4px;
	text-align: right;
	color: ${({
  bgColorKey
}) => {
  switch (bgColorKey) {
    case 'basic':
      return basicColors.gray900;
    default:
      return basicColors.white;
  }
}};

	> svg {
		margin-left: ${Spacing.xxs}px;
		margin-bottom: ${Spacing.xxs}px;
		vertical-align: middle;
	}
`;
export const MenuButton = styled.button<GnbStyleProps>`
	display: flex;
	align-items: center;
	height: 50px;
	background-color: transparent;
	margin: 17px 0 17px ${Spacing.s}px;
	padding: 13px 20px;
	border-radius: 10px;
	border: solid 1px ${basicColors.white};
	font-size: 16px;
	color: ${({
  bgColorKey
}) => {
  switch (bgColorKey) {
    case 'basic':
      return basicColors.gray900;
    default:
      return basicColors.white;
  }
}};
	cursor: pointer;
`;
export const MenuWrapper = styled.div`
	position: relative;
	display: inline-flex;
	align-items: center;

	width: max-content;
	height: ${GNB_HEIGHT.pc}px;
	padding: 38px 0;

	cursor: pointer;

	&:hover {
		> .menu-drop-down {
			opacity: 1;
			visibility: initial;
			transform: translateY(${GNB_HEIGHT.pc}px);
		}
	}
`;
export const DropDown = styled.ul`
	position: absolute;
	left: 0;
	top: 10px;

	margin: 0;
	padding: 0;
	border: solid 1px ${basicColors.gray50};
	border-radius: 10px;

	opacity: 0;
	visibility: collapse;
	transition: all 0.5s;

	overflow: hidden;
`;
export const DropDownItem = styled.li`
	list-style: none;
	min-width: max-content;
	height: 50px;
	margin: 0;
	padding: 15px 38px;
	background-color: ${basicColors.white};

	${typography.title3}
	white-space: no-wrap;
	color: ${basicColors.gray900};

	text-align: center;
	cursor: pointer;

	&:hover {
		color: ${basicColors.gray700};
	}
`;