import React from 'react';
import { IconProps } from '../index';
export const IcArrowUp = React.memo<IconProps>(({
  width = 24,
  height = 24,
  className,
  style,
  viewBox = '0 0 24 24'
}) => <svg width={width} height={height} style={style} className={className} viewBox={viewBox}>
			<g fill="none" fillRule="evenodd">
				<path d="M0 0h24v24H0z" />
				<path fill="#b0bec5" d="M12 10.116l-5.576 5.667a.718.718 0 01-1.03 0l-.18-.185a.748.748 0 010-1.047l6.237-6.34a.713.713 0 01.55-.21.71.71 0 01.548.21l6.238 6.339a.75.75 0 010 1.047l-.182.185a.718.718 0 01-1.03 0L12 10.116z" />
			</g>
		</svg>);